import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Api } from '../../services/api.service';
import { MixpanelService } from '../../services/mixpanel.service';
var DreamDestinationComponent = /** @class */ (function () {
    function DreamDestinationComponent(api, formBuilder, router, route, mixpanel) {
        this.api = api;
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.mixpanel = mixpanel;
        this.loading = false;
        this.userDestinations = [];
        this.suggestedDestinations = [];
        this.isSaved = false;
        this.searchForm = formBuilder.group({
            searchDest: [""],
        });
    }
    DreamDestinationComponent.prototype.ngOnInit = function () {
        this.whitelabelConfig = this.route.snapshot.data.whitelabel;
        this.mixpanel.track('Dream Destination Page Visited');
    };
    DreamDestinationComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.searchForm.controls.searchDest.valueChanges
            .pipe(filter(function (val) { return val; }), debounceTime(500), tap(function (_) { return _this.loading = true; }), switchMap(function (val) { return _this.api.findDestinations(val).pipe(tap(function (d) {
            _this.loading = false;
            _this.destinations = d;
        })); })).subscribe();
        this.input.nativeElement.focus();
        this.getPopularDestinations();
    };
    DreamDestinationComponent.prototype.getPopularDestinations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.api.getPopularDestinations().subscribe(function (res) {
                    _this.allPopularDestinations = res.data;
                    _this.selectRandomDestinations();
                });
                return [2 /*return*/];
            });
        });
    };
    DreamDestinationComponent.prototype.selectRandomDestinations = function () {
        var shuffled = this.allPopularDestinations.sort(function () { return 0.5 - Math.random(); }).filter(function (dest) { return dest !== null && dest !== undefined; });
        this.suggestedDestinations = shuffled.slice(0, 6);
    };
    DreamDestinationComponent.prototype.canDeactivate = function () {
        return !this.isSaved;
    };
    DreamDestinationComponent.prototype.select = function (selectedDest) {
        var _this = this;
        this.searchForm.reset();
        if (this.userDestinations.find(function (dest) { return dest.value === selectedDest.value || _this.userDestinations.length >= 10; })) {
            this.destinations = [];
            return;
        }
        this.loading = true;
        this.api.setDestination(selectedDest.value).pipe(tap(function (_) {
            _this.destinations = [];
            _this.userDestinations.push(selectedDest);
            _this.suggestedDestinations = _this.suggestedDestinations.filter(function (dest) { return dest.value !== selectedDest.value; });
            _this.loading = false;
            _this.isSaved = true;
        })).subscribe();
    };
    DreamDestinationComponent.prototype.removeDestination = function (toRemove) {
        var _this = this;
        this.loading = true;
        this.api.removeDreamDestination(toRemove.value).pipe(tap(function (_) {
            _this.userDestinations = _this.userDestinations.filter(function (d) { return d.value !== toRemove.value; });
            _this.loading = false;
        })).subscribe();
    };
    DreamDestinationComponent.prototype.setDestination = function () {
        this.router.navigate(["signup/go-premium"], {
            queryParamsHandling: "preserve",
        });
    };
    return DreamDestinationComponent;
}());
export { DreamDestinationComponent };
