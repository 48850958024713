import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import {findWhiteLabel, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {Api} from '../../services/api.service';
import {Destination, PopularDestinationsResponse} from '../../models/FlightModels';
import {MixpanelService} from '../../services/mixpanel.service';

@Component({
  selector: 'app-dream-destination',
  templateUrl: './dream-destination.component.html',
  styleUrls: ['./dream-destination.component.css']
})
export class DreamDestinationComponent implements OnInit {
  whitelabelConfig: IWhiteLabel;
  loading = false;
  searchForm: FormGroup;
  destinations: Destination[];
  userDestinations: Destination[] = [];
  suggestedDestinations: Destination[] = [];
  allPopularDestinations
  isSaved = false;
  @ViewChild("destination") input: ElementRef;

  constructor(
    public api: Api,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService){

      this.searchForm = formBuilder.group({
        searchDest: [""],
      });
  }

  ngOnInit() {
    this.whitelabelConfig = this.route.snapshot.data.whitelabel;
    this.mixpanel.track('Dream Destination Page Visited');
  }

  ngAfterViewInit() {
    this.searchForm.controls.searchDest.valueChanges
      .pipe(
        filter(val => val),
        debounceTime(500),
        tap(_ => this.loading = true),
        switchMap(val => this.api.findDestinations(val).pipe(
          tap(d => {
            this.loading = false;
            this.destinations = d
          })
        ))
      ).subscribe();
    this.input.nativeElement.focus();
    this.getPopularDestinations()
  }

  async getPopularDestinations(){
    this.api.getPopularDestinations().subscribe((res: PopularDestinationsResponse) => {
      this.allPopularDestinations = res.data
      this.selectRandomDestinations()
    })
  }

  selectRandomDestinations() {
    const shuffled = this.allPopularDestinations.sort(() => 0.5 - Math.random()) .filter(dest => dest !== null && dest !== undefined);
    this.suggestedDestinations = shuffled.slice(0, 6);
  }
  canDeactivate() {
    return !this.isSaved;
  }

  select(selectedDest: Destination) {
    this.searchForm.reset();
    if (this.userDestinations.find(dest => dest.value === selectedDest.value || this.userDestinations.length >= 10)){
      this.destinations = [];
      return;
    }
    this.loading = true;
    this.api.setDestination(selectedDest.value).pipe(
      tap(_ => {
        this.destinations = [];
        this.userDestinations.push(selectedDest);
        this.suggestedDestinations = this.suggestedDestinations.filter(dest => dest.value !== selectedDest.value);
        this.loading = false;
        this.isSaved = true;
      })
    ).subscribe();
  }

  removeDestination(toRemove: Destination) {
    this.loading = true;
    this.api.removeDreamDestination(toRemove.value).pipe(
      tap(_ => {
        this.userDestinations = this.userDestinations.filter(d => d.value !== toRemove.value);
        this.loading = false;
      })
    ).subscribe();
  }

  setDestination() {
    this.router.navigate([`signup/go-premium`], {
      queryParamsHandling: "preserve",
    });
  }
}
