export const environment = {
  production: true,
  emailValidators: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  baseUrl: "https://api2.dollarflightclub.com",
  url:"https://app.dollarflightclub.com",
  googleClientId: '656107222648-dmq835bmomr2agh6r6ass0nh5tj599o1.apps.googleusercontent.com',
  mixPanelToken: 'c16a45ae51862384042cebabb911982f',
  "stripe_key":"pk_live_lwjtrp0zHoaBMDRLnDRUONlJ",
  "app_name":"Dollar Flight Club Members Area",
  "public_area":"https://dollarflightclub.com",
  "apiContentLoadUrl":"https://api2.dollarflightclub.com/dfcmembers/contents",
  "apiSignupUrl":"https://api2.dollarflightclub.com/dfc/public/signup",
  "apiAirportsSearchUrl":"https://api2.dollarflightclub.com/dfc/public/airports/search",
  "apiSearchDestinations": "https://api2.dollarflightclub.com/dfcmembers/search/cities/all",
  "createCheckoutSession": "https://api2.dollarflightclub.com/dfc/public/stripe/checkout-session",
  "membersLoginUrl":"https://api2.dollarflightclub.com/dfcmembers/login",
  "membersLogoutUrl":"https://api2.dollarflightclub.com/dfcmembers/logout",
  "googleLogin":"https://api2.dollarflightclub.com/auth/google/login",
  "googleSignup":"https://api2.dollarflightclub.com/auth/google/signup",
  "membersSessionUrl":"https://api2.dollarflightclub.com/dfcmembers/session",
  "membersRecoverUrl":"https://api2.dollarflightclub.com/dfcmembers/recover",
  "resetTokenValidationUrl":"https://api2.dollarflightclub.com/dfcmembers/token",
  "passwordResetUrl":"https://api2.dollarflightclub.com/dfcmembers/reset",
  "premiumStatsUrl":"https://api2.dollarflightclub.com/dfcmembers/premium",
  "memberStatsUrl":"https://api2.dollarflightclub.com/dfcmembers/stats",
  "latestDealsUrl":"https://api2.dollarflightclub.com/dfcmembers/deals/latest",
  "destinationsSearchUrl":"https://api2.dollarflightclub.com/dfcmembers/search/destinations",
  "airportsSearchUrl":"https://api2.dollarflightclub.com/dfcmembers/search/airports",
  "lastDealSearchUrl":"https://api2.dollarflightclub.com/dfcmembers/deals/last",
  "dealInfoUrl":"https://api2.dollarflightclub.com/dfcmembers/deals/get",
  "dealsListUrl":"https://api2.dollarflightclub.com/dfcmembers/deals/list",
  "faqsUrl":"https://api2.dollarflightclub.com/dfcmembers/faqs",
  "openTicketUrl":"https://api2.dollarflightclub.com/dfcmembers/tickets/create",
  "ticketsListUrl":"https://api2.dollarflightclub.com/dfcmembers/tickets/list",
  "supportTicketUrl":"https://api2.dollarflightclub.com/dfcmembers/tickets/get",
  "replyTicketUrl":"https://api2.dollarflightclub.com/dfcmembers/tickets/reply",
  "membershipsListUrl":"https://api2.dollarflightclub.com/dfcmembers/memberships/list",
  "membershipSubscriptionUrl":"https://api2.dollarflightclub.com/dfcmembers/memberships/subscribe",
  "membershipCouponUrl":"https://api2.dollarflightclub.com/dfcmembers/memberships/coupon",
  "membershipUpgradeUrl":"https://api2.dollarflightclub.com/dfc/public/upgrade",
  "membershipCancelationUrl":"https://api2.dollarflightclub.com/dfcmembers/memberships/cancel",
  "membershipInfoUrl":"https://api2.dollarflightclub.com/dfcmembers/memberships/info",
  "membershipPause":"https://api2.dollarflightclub.com/dfcmembers/memberships/pause",
  "updateAccountUrl":"https://api2.dollarflightclub.com/dfcmembers/account/update",
  "newsLetterSignupUrl":"https://api2.dollarflightclub.com/dfcmembers/account/newsLetterSignup",
  "updateCustomerCardInfoUrl": "https://api2.dollarflightclub.com/dfcmembers/memberships/card/update",
  "activateNotificationsUrl":"https://api2.dollarflightclub.com/dfcmembers/notifications/activate",
  "deactivateNotificationsUrl":"https://api2.dollarflightclub.com/dfcmembers/notifications/deactivate",
  "favoriteDestinationsListUrl":"https://api2.dollarflightclub.com/dfcmembers/destinations/favorites",
  "favoriteDestinationsAddUrl":"https://api2.dollarflightclub.com/dfcmembers/destinations/favorites/add",
  "favoriteDestinationsRemoveUrl":"https://api2.dollarflightclub.com/dfcmembers/destinations/favorites/delete",
  "favoriteDeparturesListUrl":"https://api2.dollarflightclub.com/dfcmembers/departures/favorites",
  "favoriteDeparturesAddUrl":"https://api2.dollarflightclub.com/dfc/public/airports/set",
  "favoriteDeparturesRemoveUrl":"https://api2.dollarflightclub.com/dfcmembers/departures/favorites/delete",
  "destinationsListUrl":"https://api2.dollarflightclub.com/dfcmembers/search/destinations/all",
  "airlinesSearchUrl":"https://api2.dollarflightclub.com/dfcmembers/search/airlines",
  "favoriteAirlinesListUrl":"https://api2.dollarflightclub.com/dfcmembers/airlines/favorites",
  "favoriteAirlinesAddUrl":"https://api2.dollarflightclub.com/dfcmembers/airlines/favorites/add",
  "favoriteAirlinesRemoveUrl":"https://api2.dollarflightclub.com/dfcmembers/airlines/favorites/delete",
  "promotionInfoUrl":"https://api2.dollarflightclub.com/dfcmembers/promotion",
  "updateRegionUrl":"https://api2.dollarflightclub.com/dfcmembers/region",
  "hashLoginUrl":"https://api2.dollarflightclub.com/dfcmembers/hash",
  "enableDomesticUrl":"https://api2.dollarflightclub.com/dfcmembers/enabledomestic",
  "checkCouponUrl":"https://api2.dollarflightclub.com/stripe/coupon/find",
  "getPlanPriceUrl":"https://api2.dollarflightclub.com/dfc/public/stripe/price",
  "sendReferralUrl": "https://api2.dollarflightclub.com/set-referral",
  "publicCheckMembership": "https://api2.dollarflightclub.com/dfc/public/user/membership",
  "getInvoicePriceUrl": "https://api2.dollarflightclub.com/dfc/public/stripe/invoice",
  "setupIntentUrl": "https://api2.dollarflightclub.com/stripe/setup-intent",
  "updateCartIdUrl": "https://api2.dollarflightclub.com/dfcmembers/update-cart-id",
  "addConciergeUrl": "https://api2.dollarflightclub.com/stripe/add-concierge",
  "createPerkCheckoutSession": "https://api2.dollarflightclub.com/dfc/public/perk-checkout-session",
  "addFreeUserToActiveCampaignURL": "https://api2.dollarflightclub.com/active-campaign/add-free-user",
  "verifyRecaptchaUrl": "https://api2.dollarflightclub.com/auth/verify-recaptcha",
  "getNearestAirportsUrl": "https://api2.dollarflightclub.com/dfc/public/airports/nearest",
  "findPopularDestinationsUrl": "https://api2.dollarflightclub.com/dfcmembers/destinations/popular",
  "getCurrencyUrl": "https://api2.dollarflightclub.com/api/v2/currency/get",
  "getAllPerksUrl": "https://api2.dollarflightclub.com/api/v2/perks/all",
  "getPerkUrl": "https://api2.dollarflightclub.com/api/v2/perks/get",
  "extendTrialUrl": "https://api2.dollarflightclub.com/stripe/extend-trial",
  "cancellationCoupon": "iMCIjOum",
  "trialCoupon": "etjGnkpp",
  "getUserPerkProductSubscriptionUrl": "https://api2.dollarflightclub.com/api/v2/subscriptions/user"
};
