 <div class="checkout">
    <form id="payment-form" #checkout="ngForm" (submit)="onSubmit()" >
      <div class="pro-banner" *ngIf="lifetime else pplus;">
        <div>Regular Price</div>
        <div> <span style="text-decoration: line-through">$1,690</span></div>
      </div>
      <div class="pro-banner" *ngIf="(partnerId === 'premiumplus99' || partnerId === 'premiumplus991' || partnerId === 'premiumplus992') && coupon">
        <div class="pplustitle">Normally <span style="text-decoration: line-through">$99</span></div>
        <div class="pplusprice2">Get {{discount}} off now</div>
      </div>
      <div class="pro-banner" *ngIf="(partnerId === '14daysfree' || partnerId === '30daysfree' || partnerId === '6monthsfree')">
        <div class="pplustitle">Normally <span style="text-decoration: line-through">$99</span></div>
        <div class="pplusprice">Try for Free</div>
      </div>
      <ng-template #pplus>
        <div class="pro-banner" *ngIf="selectedPlanId === Memberships.premiumPlusTrial && !coupon">
          <div class="pplustitle">Normally <span style="text-decoration: line-through">$99</span></div>
          <div class="pplusprice">Just $1 to try</div>
        </div>
      </ng-template>
      <div>
        <img class="logo-center" [src]="this.logo"/>
      </div>
      <div *ngIf="!updateBilling" class="plan-info" [innerHtml]="modalInfo().modalTitle"></div>
      <div class="row perks ml-n-25" *ngIf="!updateBilling">
        <div class="col-md-12"
             *ngFor="let benefit of benefits">
          <p><i class="fa fa-check-circle-o perk-list pr-2" aria-hidden="true"></i><b>{{benefit}}</b></p>
        </div>
      </div>
      <div class="form-group">
        <label for="currency">Select Currency:</label>
        <select id="currency" class="form-control" [(ngModel)]="selectedCurrency" (change)="updateCurrency()" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let currency of availableCurrencies" [value]="currency">{{currency}}</option>
        </select>
      </div>
      <div id="link-authentication-element" #linkauth>
      </div>
      <div id="payment-element" #paymentelement>
      </div>
      <div *ngIf="!updateBilling">
        <a href="javascript:void(0);" class="promo"
           (click)="showPromo = !showPromo">Have a coupon?</a>
        <div style="display:flex;width:100%;justify-content:center; margin-bottom: 25px;" *ngIf="showPromo"><input
          type="text" class="form-control coupon" placeholder="Promo Code" [(ngModel)]="coupon"
          [ngModelOptions]="{standalone: true}"/>
          <div *ngIf="coupon" class=" btn-secondary btn-sm apply-btn" (click)="checkCoupon()">Apply
          </div>
        </div>
      </div>
      <div>
      <div id="card-errors" role="alert" *ngIf="error || couponError || validationErr" style="color:red;  margin-bottom: 1.5rem; width: 100%; display: flex; justify-content: center;">{{ validationErr || error || couponError }}</div>
      <div id="discount" role="alert" class="text-center" *ngIf="discount" style="color:#fc4c02; margin-bottom: 1.5rem; width: 100%; display: flex; justify-content: center;"> Coupon Redeemed
      </div>
      </div>
      <button type="submit" *ngIf="!error; else errorBtn" [ngClass]="'primary-btn-' + this.whitelabel_config?.css" class="btn" [disabled]="loading">
        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
        <span *ngIf="!loading"><b>{{modalInfo().buttonCTA}}</b></span>
      </button>
      <ng-template #errorBtn>
        <button (click)="tryAgain()" [ngClass]="'primary-btn-' + this.whitelabel_config?.css" class="btn" [disabled]="loading">
          <span *ngIf="!loading"><b>Try Again</b></span>
        </button>
      </ng-template>
      <div id="payment-message" class="hidden"></div>
    <div>
      <p class="mt-2 mb-0 text-left" style="font-size: 9.5px;">
        <span *ngIf="alreadyPremium">Once you select Upgrade Now, your membership will upgrade to Premium Plus+ and you will be charged for the upgrade immediately.</span>
        By continuing you agree to our <a href="https://dollarflightclub.com/terms-of-use/" target="_blank">Terms of
        Use</a>, <a href="https://dollarflightclub.com/privacy-policy/" target="_blank">Privacy Policy</a> and that your
        annual subscription will continue until you cancel. You can update your plan by logging into your account at any
        time. Our payment processing standards are fully <a
        href="https://stripe.com/guides/pci-compliance" target="_blank">PCI security</a> compliant.
      <br>
        Reminder: The  price is originally set in USD. The total charged in your currency if your plan renews may change due to variations in the exchange rate at the time of billing
      </p>
    </div>
    <div class="mt-4 text-center stripe-pwr">
        <span>
          Powered by
          <img class="stripe-logo" src="https://dfcmedia.s3.amazonaws.com/email-assets/stripe-logo.png">
        </span>
    </div>
    </form>
  </div>


