import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, NgZone, OnInit } from '@angular/core';
import { Api } from '../../services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { of } from "rxjs";
import { validationState } from "../../utilities/helpers";
import { IWhiteLabel } from "@dollar-flight-club/shared_utilities/lib/utils/whitelabel";
import { MixpanelService } from '../../services/mixpanel.service';
import { Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
var CaptureFormComponent = /** @class */ (function () {
    function CaptureFormComponent(api, formBuilder, router, ngZone, route, mixpanel) {
        this.api = api;
        this.formBuilder = formBuilder;
        this.router = router;
        this.ngZone = ngZone;
        this.route = route;
        this.mixpanel = mixpanel;
        this.showPassword = false;
        this.loading = false;
        this.liveConnect = 'travel2021';
        this.financeBuzz = 'lifetimefinancebuzz';
        this.errorMsg = null;
        this.rfsn = false;
        this.validationState = validationState;
        var emailValidators = [Validators.required, Validators.email, Validators.pattern(environment.emailValidators)];
        this.captureForm = formBuilder.group({
            email: ['', emailValidators],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }
    CaptureFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.rfsn) {
                _this.rfsn = true;
            }
            if (params.utm_source || params.utm_medium) {
                _this.source = params.utm_source;
                _this.medium = params.utm_medium;
            }
            if (params.email) {
                _this.captureForm.controls.email.setValue(params.email);
            }
        });
        this.captureForm.disable();
        this.initGoogle();
        this.api.addInitCallback(function () {
            _this.captureForm.enable();
        });
    };
    CaptureFormComponent.prototype.ngAfterViewInit = function () {
        this.emailElement.nativeElement.focus();
    };
    CaptureFormComponent.prototype.handleKeyDown = function (event) {
        if (event.keyCode === 13) {
            this.onSignupClick();
        }
    };
    CaptureFormComponent.prototype.onSignupClick = function () {
        var _this = this;
        if (this.captureForm.controls.email.valid && this.captureForm.controls.password.pristine) {
            this.showPassword = true;
            setTimeout(function () {
                _this.passwordElement.nativeElement.focus();
            }, 0);
        }
        else if (this.captureForm.controls.email.valid && this.captureForm.controls.password.valid) {
            this.loading = true;
            this.captureForm.disable();
            var userEmail = this.captureForm.controls.email.value;
            var options = {
                partnerId: this.whitelabelConfig.id,
                source: this.source,
                medium: this.medium,
                rfsn: this.rfsn
            };
            // /dfc/public/signup When clicked gets called
            this.api.signup(userEmail, this.captureForm.controls.password.value, options).pipe(tap(function (res) {
                _this.processSignup(res.user, res.token);
            }), catchError(function (err) {
                console.log(err);
                _this.loading = false;
                _this.captureForm.enable();
                _this.errorMsg = err.error.message;
                return of();
            })).subscribe();
        }
    };
    CaptureFormComponent.prototype.setACEmail = function (userEmail) {
        vgo('setEmail', userEmail);
        vgo('process');
        console.log('Active campaigns tracked', userEmail);
    };
    CaptureFormComponent.prototype.initGoogle = function () {
        var _this = this;
        // @ts-ignore
        window.onGoogleLibraryLoad = function () {
            var idConfiguration = {
                client_id: environment.googleClientId,
                callback: _this.handleCredentialResponse.bind(_this),
                auto_select: false,
                cancel_on_tap_outside: true,
                use_fedcm_for_prompt: true
            };
            google.accounts.id.initialize(idConfiguration);
            google.accounts.id.renderButton(_this.loginElement.nativeElement, { theme: 'outline', size: 'large', width: 225, logo_alignment: 'center' });
            google.accounts.id.prompt();
        };
    };
    CaptureFormComponent.prototype.handleCredentialResponse = function (response) {
        var _this = this;
        var credentials = {
            credential: response.credential,
            partner_id: this.whitelabelConfig.id,
            source: this.source,
            medium: this.medium,
            rfsn: this.rfsn
        };
        this.loading = true;
        this.api.googleAuthSignUp(credentials).pipe(tap(function (res) {
            _this.processSignup(res.user, res.token);
        }), catchError(function (err) {
            _this.ngZone.run(function () {
                _this.loading = false;
                _this.errorMsg = err.error.message;
            });
            return of();
        })).subscribe();
    };
    CaptureFormComponent.prototype.processSignup = function (user, token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uniqueId, rfsn, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.api.userEmail = user.email.toLowerCase();
                        this.mixpanel.identify((user.id).toString());
                        this.mixpanel.peopleSet({ $email: user.email, plan: Memberships.free, partnerId: user.partnerId });
                        this.mixpanel.track('Email Signup', { $email: user.email });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.setACEmail(user.email);
                        if (!this.rfsn) return [3 /*break*/, 3];
                        uniqueId = this.api.genUniqueId();
                        rfsn = {
                            cart: uniqueId,
                            id: localStorage.getItem("rfsn_v4_id"),
                            url: window.location.href,
                            aid: localStorage.getItem("rfsn_v4_aid"),
                            cs: localStorage.getItem("rfsn_v4_cs")
                        };
                        r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
                        return [4 /*yield*/, this.api.updateAccountInfo({ cartId: uniqueId })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        //BIONIC tracking
                        fbq('track', 'AddToCart');
                        if (RH && RH.trackReferral) {
                            RH.trackReferral(user.email, function () {
                            });
                        }
                        if (SL) {
                            SL.trackSubscriber(user.email);
                        }
                        //Live Connect tracking
                        if (this.whitelabelConfig.id === this.liveConnect) {
                            liQ = liQ || [];
                            liQ.push({
                                'event': 'conversion',
                                'name': 'email_signup',
                                'emailHash': user.email
                            });
                        }
                        // Finance Buzz tracking
                        if (this.whitelabelConfig.id === this.financeBuzz) {
                            tdl.convert({
                                'goal_id': '5493',
                                'adv_sub': 'dollarflightclub-js'
                            });
                        }
                        this.loading = false;
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.loading = false;
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.ngZone.run(function () {
                            return _this.router.navigate(["signup/departure"], { queryParamsHandling: 'preserve' });
                        })];
                    case 6:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return CaptureFormComponent;
}());
export { CaptureFormComponent };
