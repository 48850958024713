import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {findWhiteLabel, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {Api} from '../../services/api.service';
import {Airport} from "../../models/FlightModels";
import {MixpanelService} from '../../services/mixpanel.service';

declare var fbq: any;

@Component({
  selector: 'app-departure',
  templateUrl: './departure.component.html',
  styleUrls: ['./departure.component.css']
})
export class DepartureComponent implements OnInit {
  @ViewChild('departure') input: ElementRef;
  whitelabelConfig: IWhiteLabel;
  loading = false;
  selectedAirport: Airport;
  searchForm: FormGroup;
  airports: Airport[];
  isSaved = false;
  suggestedAirports: Airport[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              public api: Api,
              private ngxModalService: NgxSmartModalService,
              private formBuilder: FormBuilder,
              private mixpanel: MixpanelService) {

    this.searchForm=formBuilder.group({
      search:['']
    });
  }

  ngOnInit() {
    this.whitelabelConfig = this.route.snapshot.data.whitelabel;
    this.fetchSuggestedAirports();
    this.mixpanel.track('Departure Page Visited')
  }

  ngAfterViewInit() {
    this.searchForm.controls.search.valueChanges.pipe(
      filter(val => val),
      debounceTime(500),
      switchMap(val => this.api.findAirports(val).pipe(
        tap(a => this.airports = a)
      )),
    ).subscribe();
    this.input.nativeElement.focus();
  }

  async fetchSuggestedAirports() {
    try {
      const response = await this.api.getNearestAirports().toPromise();
      this.suggestedAirports = response.data.list;
    } catch (error) {
      console.error('fetchSuggestedAirports: Error fetching nearest airports:', error);
    }

    const location = await this.api.getIPLocation()
    const latitude = location.coords.latitude;
    const longitude = location.coords.longitude;
    if (latitude && longitude) {
      const response = await this.api.getNearestAirports(latitude, longitude).toPromise();
      this.suggestedAirports = response.data.list;
    }
  }

  canDeactivate() {
    return !this.isSaved;
  }

  setAirport() {
    fbq("track", "SetAirport");
    this.loading = true;
    this.api.setDepartureAirport(this.selectedAirport).pipe(
      tap(res => {
        this.loading = false;
        this.isSaved = true;
        this.router.navigate([`signup/dream-destination`], {
          queryParamsHandling: "preserve",
        });
      })
    ).subscribe()
  }

  select(id:number) {
    this.selectedAirport = this.airports.find(a => a.ID === id);
    this.airports = [];
  }
  selectSuggestedAirport(airport: any) {
    this.selectedAirport = {
      ID: airport.ID,
      code: airport.code,
      name: airport.name,
      city: airport.city
    } as Airport;
    this.airports = [];
  }

  removeSelectedAirport() {
    this.selectedAirport = null;
    this.input.nativeElement.focus();
  }
}
