import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var MixpanelService = /** @class */ (function () {
    function MixpanelService() {
        mixpanel.init(environment.mixPanelToken, {
            persistence: "localStorage",
        });
    }
    MixpanelService.prototype.track = function (msg, properties) {
        if (properties === void 0) { properties = {}; }
        mixpanel.track(msg, properties);
    };
    MixpanelService.prototype.peopleSetOnce = function (properties) {
        if (properties === void 0) { properties = {}; }
        mixpanel.people.set_once(properties);
    };
    MixpanelService.prototype.peopleSet = function (properties) {
        if (properties === void 0) { properties = {}; }
        mixpanel.people.set(properties);
    };
    MixpanelService.prototype.identify = function (id) {
        mixpanel.identify(id);
    };
    MixpanelService.ngInjectableDef = i0.defineInjectable({ factory: function MixpanelService_Factory() { return new MixpanelService(); }, token: MixpanelService, providedIn: "root" });
    return MixpanelService;
}());
export { MixpanelService };
